
export default function numberToChinese(num) {
    const chineseNums = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const chineseUnits = ['', '十', '百', '千'];
    let chineseStr = '';

    let digits = num.toString().split('').reverse();
    digits.forEach((digit, index) => {
        if (digit !== '0') {
            chineseStr = chineseNums[digit] + chineseUnits[index] + chineseStr;
        } else {
            if (chineseStr && chineseStr[0] !== '零') {
                chineseStr = '零' + chineseStr;
            }
        }
    });

    // 移除开头的零
    chineseStr = chineseStr.replace(/^零/, '');

    // 处理特殊情况，如10、100
    if (chineseStr === '') {
        chineseStr = '零';
    } else if (chineseStr.startsWith('一十') && chineseStr.length === 3) {
        chineseStr = chineseStr.replace('一十', '十');
    } else if (chineseStr.startsWith('一百') && chineseStr.length === 4) {
        chineseStr = chineseStr.replace('一百', '百');
    }

    return chineseStr;
}