<template>
  <div class="project" ref="pageBox">
    <Header></Header>
    <div class="toTop" v-show="topBtnShow" @click="scrollToTop"></div>
    <div class="item-1" ref="animate1">
      <div class="img-1"></div>
      <div class="img-box">
        <div class="box-item-1 animate-1-1" :class="animateStart_1?'animate__animated animate__fadeIn' :'displayNone'"></div>
        <div class="box-item-2 animate-1-2" :class="animateStart_1?'animate__animated animate__fadeIn' :'displayNone'"></div>
        <div class="box-item-3 animate-1-3" :class="animateStart_1?'animate__animated animate__fadeIn' :'displayNone'"></div>
        <div class="box-item-4 animate-1-4" :class="animateStart_1?'animate__animated animate__fadeIn' :'displayNone'"></div>
      </div>

    </div>
    <div class="item-2">
      <div class="img-7"></div>
      <div class="publicize">
        <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
      </div>
    </div>
    <div class="item-3" ref="animate2">
      <div class="img-8"></div>
      <div class="sub-title">
        合规连锁经营 全国超1000家体验中心
      </div>
      <div class="content-text">
        <p>“AI+教育”时代持续领跑</p>
        <p>自主研发英语学习垂直领域大语言模型 </p>
        <p>为创业者，为机构转型、拓科提供新机遇</p>
      </div>
      <div class="bg-img-1"></div>
      <div class="img-box">
        <div class="box-item-1 animate-2-1" :class="animateStart_2?'animate__animated animate__fadeIn' :'displayNone'"></div>
        <div class="box-item-2 animate-2-2" :class="animateStart_2?'animate__animated animate__fadeIn' :'displayNone'"></div>
      </div>
      <div class="title"></div>
      <div class="lzBox">
        <div class="name">姓 名：</div>
        <div class="name_inp"><input type="text" v-model="name" placeholder="请输入您的姓名" /></div>
        <div class="phone">联系方式：</div>
        <div class="phone_inp"><input type="text" v-model="phone" placeholder="请输入您的联系方式" :maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" pattern="[0-9]*" /></div>
        <div class="btn fireworks" @click="sendMsg"><img src="../assets/img/index/btn.png" alt=""></div>
      </div>
    </div>
    <div class="item-4" ref="animate3">
      <div class="text-box">
        <div class="box-item box-1 animate-3-1" :class="animateStart_3?'animate__animated animate__fadeInUp' :'displayNone'">
          <div class="box-title">
            <div class="tit-icon"></div>
            智能测评
          </div>
          <div class="box-text">
            <p>水平测试智能分析测试结果</p>
            <p>根据基础个性化推荐学习内容</p>
          </div>
        </div>
        <div class="box-item box-2 animate-3-2" :class="animateStart_3?'animate__animated animate__fadeInUp' :'displayNone'">
          <div class="box-title">
            <div class="tit-icon"></div>
            人机互动
          </div>
          <div class="box-text">
            <p>训练数据收集分析</p>
            <p>智能规划记忆路径</p>
          </div>
        </div>
        <div class="box-item box-3 animate-3-3" :class="animateStart_3?'animate__animated animate__fadeInUp' :'displayNone'">
          <div class="box-title">
            <div class="tit-icon"></div>
            智能复习
          </div>
          <div class="box-text">
            <p>云端计算结合大数据分析</p>
            <p>精算最佳复习时间并提醒复习</p>
          </div>
        </div>
        <div class="box-item box-4 animate-3-4" :class="animateStart_3?'animate__animated animate__fadeInUp' :'displayNone'">
          <div class="box-title">
            <div class="tit-icon"></div>
            远程伴学
          </div>
          <div class="box-text">
            <p>1V1伴学就像老师坐在身边</p>
            <p>学习有人管家长放心</p>
          </div>
        </div>
      </div>
    </div>

    <div class="item-5">
      <div class="content-img"></div>
    </div>

    <div class="item-6">
      <div class="content-img"></div>
    </div>

    <div class="item-7" ref="animate4">
      <div class="title">6大智能系统支持店面标准化运营</div>
      <div class="sub-title">降低管理成本 高效能运营单店可复制</div>
      <div class="animate-box">
        <div class="box-item">
          <div class="item-text text1 animate-4-1" :class="animateStart_4?'animate__animated animate__fadeIn' :'displayNone'">
            <p class="text-tit">学 习</p>
            <p class="text-text">智能英语训练系统</p>
          </div>
          <div class="item-img img1"></div>
        </div>
        <div class="box-item">
          <div class="item-text text2 animate-4-2" :class="animateStart_4?'animate__animated animate__fadeIn' :'displayNone'">
            <p class="text-tit">教 学</p>
            <p class="text-text">学习管理系统</p>
          </div>
          <div class="item-img img2"></div>
        </div>
        <div class="box-item">
          <div class="item-text text3 animate-4-3" :class="animateStart_4?'animate__animated animate__fadeIn' :'displayNone'">
            <p class="text-tit">督 学</p>
            <p class="text-text">远程督学服务系统</p>
          </div>
          <div class="item-img img3"></div>
        </div>
        <div class="box-item">
          <div class="item-text text4 animate-4-4" :class="animateStart_4?'animate__animated animate__fadeIn' :'displayNone'">
            <p class="text-tit">反 馈</p>
            <p class="text-text">学情反馈系统</p>
          </div>
          <div class="item-img img4"></div>
        </div>
        <div class="box-item">
          <div class="item-text text5 animate-4-5" :class="animateStart_4?'animate__animated animate__fadeIn' :'displayNone'">
            <p class="text-tit">培 训</p>
            <p class="text-text">线上培训系统</p>
          </div>
          <div class="item-img img5"></div>
        </div>
        <div class="box-item">
          <div class="item-text text6 animate-4-6" :class="animateStart_4?'animate__animated animate__fadeIn' :'displayNone'">
            <p class="text-tit">运 营</p>
            <p class="text-text">运营管理系统</p>
          </div>
          <div class="item-img img6"></div>
        </div>
      </div>
    </div>

    <div class="item-8" ref="animate5_1">
      <div class="title">6大运营支持体系</div>
      <div class="title-2">从0到1开业落地帮扶</div>
      <div class="sub-title">筹建、培训、营销、品宣、运营、售后全方位支持</div>
      <div class="content-box">
        <div class="box-item">
          <div class="item-title item-title-b">
            <div class="title-icon"></div>
            从0到1开业落地支持
          </div>
          <div class="item-content">
            <div class="img-1"></div>
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            <div class="title-icon"></div>
            8大培训支持体系
          </div>
          <div class="item-content">
            <div class="content-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;产品、销售、服务、运营、直播培训、优秀合作伙伴经验分享，区域经验交流会等培训支持，周周有培训。</div>
            <div class="img-2"></div>
            <div class="img-text">洛阳运营中心-运营基础培训</div>
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            <div class="title-icon"></div>
            1年5大营销活动支持
          </div>
          <div class="item-content">
            <div class="content-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1年5次总部承担奖学金、奖品的全国大型活动，助力合作伙伴宣传招生。</div>
            <div class="img-3"></div>
            <div class="img-text">2024全国最快单词王争霸赛-湖南娄底娄星城市赛</div>
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            <div class="title-icon"></div>
            每日营销宣传物料支持
          </div>
          <div class="item-content">
            <div class="content-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;每日1宣传视频、节日节气宣传海报，每周1直播，另有产品宣传海报、宣传册等宣传推广物料支持。</div>
            <div class="img-4"></div>
            <div class="img-text">每日宣传视频</div>
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            <div class="title-icon"></div>
            1V1运营服务支持
          </div>
          <div class="item-content">
            <div class="content-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;每个合作伙伴都拥有专属VIP服务群，1个客服经理，1个市场经理提供7*12小时管家式服务。</div>
            <div class="img-5" ref="animate5"></div>
            <div class="img-text">1v1专属服务群</div>
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            <div class="title-icon"></div>
            售后服务支持
          </div>
          <div class="item-content">
            <div class="content-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;总部提供三包、即时售后服务、维修支持。</div>
            <div class="img-6-jpg" v-if="!animateStart_5"></div>
            <div class="img-6" v-else></div>
          </div>
        </div>
      </div>
    </div>

    <div class="item-9">
      <div class="title">{{ this.setnumberToChinese(this.Global.year)}}年专注智能英语 做好一件事</div>
      <div class="sub-title">让所有学生成才 让所有伙伴成功</div>
      <div class="end-img"></div>
      <div class="lzBox-title"></div>
      <div class="lzBox">
        <div class="name">姓 名：</div>
        <div class="name_inp"><input type="text" v-model="name" placeholder="请输入您的姓名" /></div>
        <div class="phone">联系方式：</div>
        <div class="phone_inp"><input type="text" v-model="phone" placeholder="请输入您的联系方式" :maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" pattern="[0-9]*"/></div>
        <div class="btn fireworks" @click="sendMsg"><img src="../assets/img/index/btn.png" alt=""></div>
      </div>

    <FooterText :pageStage="4"></FooterText>
      <!-- <div class="bottom">
        <p>版权所有：北京红本科技有限公司</p>
        <p>投资有风险，选择需谨慎</p>
        <p>地址：北京市海淀区上地信息路11号彩虹大厦北楼西段203室</p>
        <p>电话：4008-017-018</p>
      </div> -->
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant';
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";
Swiper.use([Autoplay, Navigation, Pagination]);
import Header from "@/components/header";
import FooterText from './footerText.vue'
import "swiper/swiper-bundle.css";
import numberToChinese from "../utils/numberToChinese"
export default {
  name: "index",
  components: {
    Header,
    FooterText,
  },
  data() {
    return {
      topBtnShow:false,
      name:"",
      phone:"",
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: "https://sound.hssenglish.com/redbook/video/818sign_in_new2.mp4", //url地址
          },
        ],
        poster: require("../assets/img/project/video-img.png") , //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      animateStart_1: false,
      animateStart_2: false,
      animateStart_3: false,
      animateStart_4: false,
      animateStart_5: false,
      animate1Height: 0,
      animate2Height: 0,
      animate3Height: 0,
      animate4Height: 0,
      animate5_1Height: 0,
      animate5Height: 0,
      imgList_1: [
        {
          url: "coreTechnologies/swiper-1-1.jpg",
          text: "杨宁远博士在北京大学读核物理本科结束时，由于对大脑信息处理机制发生兴趣, 并特别关注用无损伤的大脑成像技术来揭示与信息处理相关的大脑活动，赴美国纽约大学攻读心理学博士学位。",
        },
        {
          url: "coreTechnologies/swiper-1-2.jpg",
          text: "1998年获得博士学位之后，受邀到宾夕法尼亚大学认知科学研究所，开始从事脑电波和大脑信息处理的研究。",
        },
        {
          url: "coreTechnologies/swiper-1-3.jpg",
          text: "2000年4月，受聘到斯坦福大学语言信息研究中心做研究员，研究如何用脑波来识别大脑的语言信息处理过程。其主要的学术成果体现在博士论文“人类视觉镜象记忆的存活时间的特性与神经机制”。该论文被乔治•斯佩林博士（美国科学院院士）列为他的研究生必读文章。",
        },
        {
          url: "coreTechnologies/swiper-1-4.jpg",
          text: "纽约大学神经磁学实验室的导师Dr. Kaufman描述了杨博士在纽约大学的工作经历：“杨博士对原先镜像记忆的研究方法上做了突破性改进，创造出了精确的对镜像记忆存活时间定量测量的方法，他的研究对记忆缺陷(如老年痴呆—阿兹海默症)的早期发现具有重要的意义。”",
        },
        {
          url: "coreTechnologies/swiper-1-5.jpg",
          text: "纽约大学心理学教授，世界著名的视觉感知和磁神经学专家这样评价杨博士的贡献：“杨博士的研究一直都处在人类信息处理研究的前沿。他的研究跟人类的健康和疾病的问题也紧密相关。由于杨博士研究的重要性，对现实生活中人类的学习，记忆和病理学的研究产生了重要的影响，他的工作对国家的利益有卓越的贡献。我相信随着这些发现不断地被重复和改进，它们将会是在人类认知神经学方面最令人振奋的发现之一。”",
        },
      ],
      imgList_2: [
        {
          url: "coreTechnologies/swiper-2-1.jpg",
          text: "2001年，杨博士从斯坦福大学博士后出站后，在美国硅谷创立记忆引擎为核心技术的高科技公司，研发和推广记忆引擎技术平台上的产品。",
        },
        {
          url: "coreTechnologies/swiper-2-2.jpg",
          text: "2002年，杨博士带着记忆引擎技术回到祖国，从云南开始，开启了记忆引擎在中国的推广和普及之路。",
        },
      ],
      imgList_3: [
        "coreTechnologies/swiper-3-1.jpg",
        "coreTechnologies/swiper-3-2.jpg",
        "coreTechnologies/swiper-3-3.jpg",
        "coreTechnologies/swiper-3-4.jpg",
        "coreTechnologies/swiper-3-5.jpg",
        "coreTechnologies/swiper-3-6.jpg",
        "coreTechnologies/swiper-3-7.jpg",
        "coreTechnologies/swiper-3-8.png",
        "coreTechnologies/swiper-3-9.png",
      ],
    };
  },
  created() {},
  mounted() {
    if (!this._isMobile()) {
      // 跳转至 pc 端路由
      // this.$router.push({
      //   path: '/'
      // })
      let app = document.querySelector('#app');
      app.style.width = "750px";
      app.classList.add("pcStyle");
    }
    this.$nextTick(() => {
      this.swiperInit();
    });
  },
  methods: {
    sendMsg() {
      if(this.name && this.phone){
        let num_reg = /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|16[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}|17[0-9]{1}[0-9]{8}$|19[0-9]{1}[0-9]{8}$|14[0-9]{1}[0-9]{8}/;
        if(!num_reg.test(this.phone)){
          Toast('请填写正确的手机号');
          return false;
        }
        if(this.name.length>10){
          Toast('姓名不可以大于10位');
          return false;
        }
        let data = {
          name: this.name,
          phone: this.phone,
          msg:"项目合作"
        };
        let formData = new FormData();
        for (let dataKey in data) {
          formData.append(dataKey, data[dataKey]);
        }
        let url= ""
        if (process.env.NODE_ENV == "production") {
          //生产环境
          url = "https://api.xiaohongben888.com/product/submitProductInquiry"
        }else{
          //本地环境
          url = "/api/product/submitProductInquiry"
        }

        axios.post(url, formData).then(function (response) {
          console.log(response);
          if(response.data.code==200){
            Toast('提交成功,项目经理将在24小时内与您联系。');
          }
        }).catch(function (error) {
          console.log(error);
        });
      }else {
        Toast('请填写完整信息');
      }
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    swiperInit() {
      let swiper2 = new Swiper(".swiper-2", {
        direction: "horizontal", // 垂直切换选项
        loop: true, // 循环模式选项

        // 如果需要分页器
        pagination: {
          el: ".swiper-2-pagination",
          click: true,
        },
        navigation: {
          nextEl: ".swiper-2-button-next",
          prevEl: ".swiper-2-button-prev",
        },
        // autoplay: {
        //   delay: 2000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: true,
        // },

        // 如果需要滚动条
        // scrollbar: {
        //   el: '.swiper-scrollbar',
        // },
      });

      let swiper3 = new Swiper(".swiper-3", {
        direction: "horizontal" /*横向滑动*/,
        observeParents: false, //必须加入
        observer: true, //必须加入
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 30,
        loop: true,
        autoplay: true,
      });

      window.addEventListener("scroll", this.scrollListener, true);

      this.animate1Height = this.$refs.animate1.offsetTop;
      this.animate2Height = this.$refs.animate2.offsetTop;
      this.animate3Height = this.$refs.animate3.offsetTop;
      this.animate4Height = this.$refs.animate4.offsetTop;
      this.animate5Height = this.$refs.animate5.offsetTop;
      this.animate5_1Height = this.$refs.animate5_1.offsetTop;
      console.log("this.animate5_1Height",this.animate5_1Height)
      console.log("this.animate5Height",this.animate5Height)
    },
    scrollToTop(){
      // this.$refs.pageBox.scrollTo({top: 0, behavior: 'smooth'});
      this.$refs.pageBox.scrollTo({top: 0});
    },
    scrollListener() {
      // let scrollTop =
      //   window.pageYOffset ||
      //   document.documentElement.scrollTop ||
      //   document.body.scrollTop;
      let scrollTop;
      if(this.$refs.pageBox){
      scrollTop = this.$refs.pageBox.scrollTop;
      }
      let height = window.innerHeight;
      if(scrollTop>height){
        this.topBtnShow = true;
      }else {
        this.topBtnShow = false;
      }
      if (scrollTop > this.animate1Height - 200) {
        this.animateStart_1 = true;
      }
      if (scrollTop > this.animate2Height - 200) {
        this.animateStart_2 = true;
      }
      if (scrollTop > this.animate3Height - 200) {
        this.animateStart_3 = true;
      }
      if (scrollTop > this.animate4Height - 200) {
        this.animateStart_4 = true;
      }
      if (scrollTop > this.animate5Height + this.animate5_1Height) {
        this.animateStart_5 = true;
      }
    },
    setnumberToChinese(num){
      return numberToChinese(num)
    }
  },
};
</script>

<style lang="scss" scoped>
*{
  font-family: HarmonyOS Sans SC;
}
.fireworks {
    animation: pulse 0.5s infinite;
}
 
@keyframes fireworks {
    0% {
        transform: scale(1);
        /*开始为原始大小*/
    }
 
    25% {
        transform: scale(1.3);
        /*放大1.1倍*/
    }
 
    50% {
        transform: scale(1);
    }
 
    75% {
        transform: scale(1.3);
    }
 
}
.toTop {
  position: fixed;
  right:20px;
  bottom:150px;
  z-index: 10;
  width:50px;
  height:50px;
  opacity: 0.7;
  background: url("../assets/img/project/top.png") round;
}
.displayNone {
  display: none !important;
}
.animate-1-1,
.animate-2-1,
.animate-3-1,
.animate-4-1 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  --animate-delay: 0.5s;
}
.animate-1-2,
.animate-2-2,
.animate-3-2,
.animate-4-2 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
  --animate-delay: 1s;
}
.animate-1-3,
.animate-3-3,
.animate-4-3 {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s;
  --animate-delay: 1.5s;
}
.animate-1-4,
.animate-3-4,
.animate-4-4 {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
  --animate-delay: 2s;
}
.animate-4-5 {
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  -o-animation-delay: 2.5s;
  animation-delay: 2.5s;
  --animate-delay: 2.5s;
}
.animate-4-6 {
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  animation-delay: 3s;
  --animate-delay: 3s;
}

.project {
  height:100vh;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  background: rgba(165, 165, 165, 0.18);
  .item-1,
  .item-2,
  .item-3,
  .item-4,
  .item-5,
  .item-6,
  .item-7,
  .item-8,
  .item-9,
  .item-10 {
    background: #fff;
    margin-bottom: 10px;
  }
  .item-1 {
    padding: 59px 0 45px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .img-1 {
      background: url("../assets/img/project/img-1.png") round;
      width: 375px;
      height: 237px;
      margin-bottom: 8px;
    }
    .img-box {
      width: 281px;
      height: 324px;
      background: url("../assets/img/project/img-6.png") round;
      position: relative;
      .box-item-1 {
        position: absolute;
        left: 8px;
        top: 42px;
        width: 99px;
        height: 106px;
        background: url("../assets/img/project/img-2.png") round;
      }

      .box-item-2 {
        position: absolute;
        right: 8px;
        top: 42px;
        width: 99px;
        height: 106px;
        background: url("../assets/img/project/img-3.png") round;
      }

      .box-item-3 {
        position: absolute;
        left: 8px;
        bottom: 20.5px;
        width: 99px;
        height: 106px;
        background: url("../assets/img/project/img-4.png") round;
      }

      .box-item-4 {
        position: absolute;
        right: 8px;
        bottom: 20.5px;
        width: 99px;
        height: 106px;
        background: url("../assets/img/project/img-5.png") round;
      }
    }
  }

  .item-2 {
    padding: 19px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .img-7 {
      background: url("../assets/img/project/img-7.png") round;
      width: 233px;
      height: 54px;
      margin-bottom: 8px;
    }
    .publicize {
      width: 100%;
      height: 211px;
      margin-top: 13.5px;
    }
  }

  .item-3 {
    position: relative;
    padding: 49px 0 45px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .img-8 {
      position: relative;
      z-index: 1;
      background: url("../assets/img/project/img-8.png") round;
      width: 318px;
      height: 25px;
      margin-bottom: 10px;
    }

    .sub-title {
      position: relative;
      z-index: 1;
      font-size: 16px;
      color: #333;
      font-weight: 600;
      margin-bottom: 28px;
    }
    .content-text {
      position: relative;
      z-index: 1;
      font-size: 14px;
      color: #999;
      line-height: 25px;
      padding: 0 28px;
      text-align: left;
      margin-bottom: 53px;
      p {
        text-align: center;
      }
    }
    .bg-img-1 {
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 375px;
      height: 644px;
      background: url("../assets/img/project/bg-img-1.png") no-repeat;
      background-size: 100% 100%;
    }
    .img-box {
      width: 335px;
      height: 280px;
      position: relative;
      margin-bottom: 86px;
      .box-item-1 {
        position: absolute;
        left: 53.5px;
        top: 102px;
        width: 225px;
        height: 202px;
        background: url("../assets/img/project/img-10.png") round;
      }
      .box-item-2 {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 335px;
        height: 280px;
        background: url("../assets/img/project/img-9.png") round;
      }
    }
    .title {
      z-index: 1;
      position: relative;
      width: 100%;
      height: 195px;
      background: url("../assets/img/project/img-11.png");
      background-size: 100% 195px;
    }
    .lzBox {
      z-index: 2;
      position: relative;
      width: 346.66px;
      height: 269.02px;
      border-radius: 5px 5px 5px 5px;
      background: #ffffff;
      box-shadow: 0 2.5px 5px 1.5px #a5a5a52e;
      margin: 0 auto;
      margin-top: -90px;
      padding: 30px 25px;
      box-sizing: border-box;
      .name {
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        text-align: left;
      }
      .name_inp {
        margin-top: 9px;
        input {
          width: 296px;
          height: 37px;
          border: 1px solid #999;
          border-radius: 5px;
          box-sizing: border-box;
          padding-left: 14px;
          font-size: 14px;
        }
      }
      .phone {
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        text-align: left;
        margin-top: 21px;
      }
      .phone_inp {
        margin-top: 9px;
        input {
          width: 296px;
          height: 37px;
          border: 1px solid #999;
          border-radius: 5px;
          box-sizing: border-box;
          padding-left: 14px;
          font-size: 14px;
        }
      }
      .btn {
        width: 296px;
        height: 37px;
        font-size: 0;
        font-size: 14px;
        margin: 0 auto;
        margin-top: 25px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .item-4 {
    position: relative;
    width: 375px;
    height: 680px;
    background: url("../assets/img/project/img-12.png") no-repeat;
    background-size: 100% 100%;
    .text-box {
      position: absolute;
      bottom: 40px;
      left: 58px;
      height: 120px;
      // width: 275px;
      // height: 400px;

      .box-item {
        position: absolute;
        width: 130px;
        .box-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 15px;
          color: #fff;
          font-weight: bold;
          .tit-icon {
            width: 13px;
            height: 13px;
            background: url("../assets/img/project/icon-1.png") round;
            margin-right: 4.5px;
          }
        }
        .box-text {
          font-size: 10px;
          line-height: 17.5px;
          text-align: left;
          color: #fff;
          p {
            color: #fff;
          }
        }
      }
      .box-1 {
        top: 0;
        left: 0;
      }
      .box-2 {
        top: 0;
        left: 160px;
      }
      .box-3 {
        bottom: 0;
        left: 0;
      }
      .box-4 {
        bottom: 0;
        left: 160px;
      }
    }
  }

  .item-5 {
    position: relative;
    width: 375px;
    height: 647px;
    display: flex;
    justify-content: center;
    align-items: center;
    .content-img {
      width: 325px;
      height: 559px;
      background: url("../assets/img/project/img-13.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .item-6 {
    position: relative;
    width: 375px;
    height: 674px;
    display: flex;
    justify-content: center;
    align-items: center;
    .content-img {
      width: 375px;
      height: 558px;
      background: url("../assets/img/project/img-14.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .item-7 {
    position: relative;
    padding: 60px 0 63px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-size: 22.5px;
      color: #df0522;
      margin-bottom: 11px;
      font-weight: 600;
    }
    .sub-title {
      font-size: 16px;
      color: #333;
      font-weight: 600;
      margin-bottom: 24px;
    }
    .animate-box {
      padding: 0 29px;
      .box-item {
        position: relative;
        width: 316px;
        height: 82.5px;
        .item-text,
        .item-img {
          position: absolute;
          width: 158px;
          height: 82px;
          top: 0;
        }
        .item-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .text-tit {
            font-size: 16px;
            text-align: center;
            color: #df0522;
            margin-bottom: 10px;
          }
          .text-text {
            font-size: 14px;
            text-align: center;
            color: #333;
          }
        }
        .item-img {
          width: 158px;
          height: 82.5px;
        }
        .text1,
        .text3,
        .text5,
        .img2,
        .img4,
        .img6 {
          left: 0;
        }
        .text2,
        .text4,
        .text6,
        .img1,
        .img3,
        .img5 {
          right: 0;
        }
        .img1 {
          background: url("../assets/img/project/list-1.png") no-repeat;
          background-size: 100% 100%;
        }
        .img2 {
          background: url("../assets/img/project/list-2.png") no-repeat;
          background-size: 100% 100%;
        }
        .img3 {
          background: url("../assets/img/project/list-3.png") no-repeat;
          background-size: 100% 100%;
        }
        .img4 {
          background: url("../assets/img/project/list-4.png") no-repeat;
          background-size: 100% 100%;
        }
        .img5 {
          background: url("../assets/img/project/list-5.png") no-repeat;
          background-size: 100% 100%;
        }
        .img6 {
          background: url("../assets/img/project/list-6.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }

  .item-8 {
    position: relative;
    padding: 60px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-size: 22.5px;
      color: #df0522;
      margin-bottom: 6px;
      font-weight: 600;
    }
    .title-2 {
      font-size: 22.5px;
      color: #df0522;
      margin-bottom: 11px;
      font-weight: 600;
    }
    .sub-title {
      font-size: 15px;
      color: #333;
      font-weight: 600;
      margin-bottom: 29px;
    }
    .content-box {
      .box-item {
        margin-bottom: 30px;
        .item-title {
          padding: 0 18px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          color: #333;
          font-weight: 600;
          margin-bottom: 12px;
          .title-icon {
            width: 24px;
            height: 18px;
            background: url("../assets/img/project/icon-2.png") round;
            margin-right: 10.5px;
          }
        }
        .item-title-b {
          margin-bottom: 21px;

        }
      }
      .item-content {
        text-align: center;
        
        .content-text{
          text-align: left;
        }
        .content-text,
        .img-text {
          font-size: 14px;
          color: #999;
          line-height: 29px;
          padding: 0 28px;
        }
        .img-text {
          text-align: center;
        }
        .img-1 {
          display: inline-block;
          width: 317px;
          height: 61px;
          background: url("../assets/img/project/box-1.png") no-repeat;
          background-size: 100% 100%;
        }
        .img-2 {
          width: 375px;
          height: 237px;
          background: url("../assets/img/project/box-2.png") no-repeat;
          background-size: 100% 100%;
          margin-bottom: 12px;
        }
        .img-3 {
          width: 375px;
          height: 196px;
          background: url("../assets/img/project/box-3.png") no-repeat;
          background-size: 100% 100%;
          margin-bottom: 12px;
        }
        .img-4 {
          width: 375px;
          height: 223px;
          background: url("../assets/img/project/box-4.png") no-repeat;
          background-size: 100% 100%;
          // margin-bottom: 12px;
        }
        .img-5 {
          width: 375px;
          height: 230px;
          background: url("../assets/img/project/box-5.png") no-repeat;
          background-size: 100% 100%;
          // margin-bottom: 12px;
        }
        .img-6 {
          width: 375px;
          height: 465px;
          background: url("../assets/img/project/box-6.gif") no-repeat;
          background-size: 100% 100%;
        }
        .img-6-jpg {
          width: 375px;
          height: 465px;
          background: url("../assets/img/project/box-6.jpg") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }

  .item-9 {
    position: relative;
    padding: 60px 0 36px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-size: 22.5px;
      color: #df0522;
      margin-bottom: 6px;
      font-weight: 600;
    }
    .sub-title {
      font-size: 16px;
      color: #333;
      font-weight: 600;
      margin-bottom: 29px;
    }
    .end-img {
      width: 375px;
      height: 438px;
      background: url("../assets/img/project/img-15.png") no-repeat;
      background-size: 100% 100%;
      margin-bottom: 39px;
    }

    .lzBox-title {
      z-index: 1;
      position: relative;
      width: 100%;
      height: 195px;
      background: url("../assets/img/project/img-11.png");
      background-size: 100% 195px;
    }
    .lzBox {
      z-index: 2;
      position: relative;
      width: 346.66px;
      height: 269.02px;
      border-radius: 5px 5px 5px 5px;
      background: #ffffff;
      box-shadow: 0 2.5px 5px 1.5px #a5a5a52e;
      margin: 0 auto;
      margin-top: -90px;
      padding: 30px 25px;
      box-sizing: border-box;
      .name {
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        text-align: left;
      }
      .name_inp {
        margin-top: 9px;
        input {
          width: 296px;
          height: 37px;
          border: 1px solid #999;
          border-radius: 5px;
          box-sizing: border-box;
          padding-left: 14px;
          font-size: 14px;
        }
      }
      .phone {
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        text-align: left;
        margin-top: 21px;
      }
      .phone_inp {
        margin-top: 9px;
        input {
          width: 296px;
          height: 37px;
          border: 1px solid #999;
          border-radius: 5px;
          box-sizing: border-box;
          padding-left: 14px;
          font-size: 14px;
        }
      }
      .btn {
        width: 296px;
        height: 37px;
        font-size: 0;
        margin: 0 auto;
        margin-top: 25px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .bottom {
      padding: 36px 0 100px;
      p {
        color: #999999;
        font-weight: 400;
        font-size: 11.5px;
        text-align: center;
        line-height: 20.09px;
      }
    }
  }
}
</style>
<style lang="scss">
.pcStyle{
  // width:750px;
  position: relative;
  margin:0 auto;
}
.van-toast  {
  .van-toast__text {
    color: #fff;

  }
}
</style>